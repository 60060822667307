import {
  backgroundImage,
  backgroundImageText,
  container,
  description,
  section,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const pastWinnersImageStyle = theme => ({
  backgroundImage: {
    ...backgroundImage,
    height: "600px",
    borderBottomRightRadius: "6px",
    borderBottomLeftRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      marginBottom: "80px"
    },
  },
  backgroundImageText,
  description: {
    ...description,
    textAlign: "center",
    color: whiteColor,
  },
  descriptionSmall: {
    ...description,
    textAlign: "center",
    fontSize: ".9rem",
    marginBottom: "20px",
    color: whiteColor,
  },
  registerItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  registerButton: {
    margin: "0 auto",
  },
  container,
  section: {
    ...section,
    position: "relative",
  },
  subtitle: {
    ...title,
    color: whiteColor,
    textAlign: "center",
    marginBottom: "10px",
  },
  title: {
    ...title,
    textAlign: "center",
    color: whiteColor,
  },
});

export default pastWinnersImageStyle
