// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import pastWinnersImageStyle from "assets/jss/material-kit-pro-react/views/pastWinnersPageSections/pastWinnersImageStyle.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(pastWinnersImageStyle)

export default function PastWinnersImage() {
  const { pastWinnersBackground } = useStaticQuery(graphql`
    query getPastWinnersImage {
      pastWinnersBackground: file(
        relativePath: { eq: "backgrounds/past-winners.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={pastWinnersBackground.childImageSharp.gatsbyImageData}
        alt="This Year's Winners Photo"
      />
    </div>
  )
}
